export enum BULLETIN_FILTERS {
  BULLETIN_ENTITY = 'Bulletin Entity',
  BULLETIN_LEVEL = 'Bulletin Level',
  VENDOR_LOCATION_AIRPORT = 'Vendor Location Airport',
  VENDOR_LOCATION_AIRPORT_CODE = 'Vendor Location Airport Code',
  BULLETIN_SOURCE = 'Bulletin Source',
  BULLETIN_PRIORITY = 'Bulletin Priority',
  UA_OFFICE = 'UA Office',
  BULLETIN_TYPE = 'Bulletin Type',
  BULLETIN_CAPPS_CATEGORY_CODE = 'Bulletin CAPPS Category Code',
  NOTAM_ID = 'Notam Id',
}

export enum ASSOCIATED_BULLETIN_FILTERS {
  VENDOR_LOCATION_AIRPORT = 'Vendor Location Airport',
  VENDOR_LOCATION_AIRPORT_CODE = 'Vendor Location Airport Code',
  BULLETIN_SOURCE = 'Bulletin Source',
  BULLETIN_PRIORITY = 'Bulletin Priority',
  UA_OFFICE = 'UA Office',
  BULLETIN_TYPE = 'Bulletin Type',
  BULLETIN_CAPPS_CATEGORY_CODE = 'Bulletin CAPPS Category Code',
  NOTAM_ID = 'Notam Id',
}
